.Pricing {
  display: flex;
  justify-content: center;
  padding: 10px;
  height: 100vh;
  color: white;
  text-align: left;
  background-color: #181716;
}

.PricingSection {
  width: 20%;
  background-color: #181716;
  border-radius: 5px;
  margin: 30px 15px;
  padding: 40px;
  /* display: flex;
  flex-direction: column; */
  /* align-items: center; */
}

.PricingHeader {
  font-size: 4vh;
  font-weight: bold;
  padding: 0 0 10px 0;
}

.PricingSubheader {
  color: lightgray;
  padding: 0 0 50px 0;
  font-size: 2vh;
}

.PriceSection {
  display: flex;
  align-items: flex-end;
  padding: 0 0 20px 0;
}

.DollarAmmount {
  font-size: 6vh;
  font-weight: bold;
}

.TimeWindow {
  font-size: 1.7vh;
  padding: 0 0 10px 5px;
  color: lightgray;
}

.SubscriptionButton {
  border: none;
  background-color: #c06205;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 3vh;
  text-decoration: none;
  width: 100%;
}

.Description {
  padding: 20px 0 0 0;
  font-size: 2vh;
}

.DescriptionBullet {
  padding: 10px 0;
}

#Featured {
  background-color: #3b3b3b;
  border: 2px solid #636363;
}

.Highlight {
  padding: 0 0 20px 0;
}

@media only screen and (max-width: 1000px) {
  .Pricing {
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: auto;
    min-height: 100vh;
  }
  .PricingSection {
    width: 90%;
    margin: 0;
    padding: 50px 10px;
  }

  .PricingHeader {
    font-size: 30px;
  }

  .PricingSubheader {
    font-size: 25px;
  }

  .DollarAmmount {
    font-size: 40px;
    font-weight: bold;
  }

  .TimeWindow {
    font-size: 13px;
    padding: 0 0 10px 5px;
    color: lightgray;
  }

  .Description {
    font-size: 15px;
  }

  .SubscriptionButton {
    border: none;
    background-color: #c06205;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 20px;
    text-decoration: none;
    width: 99%;
    margin: 10px 0;
  }
}
