.Disclaimers {
  padding: 20vh 10vw 0 10vw;
  background-color: #181716;
  color: lightgray;
}

.DisclaimerHeader {
  padding: 0 0 2vh 0;
  font-size: 1vh;
}

.DisclaimerText {
  font-size: 1vh;
  padding: 0 0 2vh 0;
}
