.inputField {
  width: 98%;
  height: 4vh;
  font-size: 2vh;
}

@media only screen and (max-width: 1000px) {
  .inputField {
    height: 20px;
    font-size: 15px;
  }
}
