html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.NavBar {
  background-color: #181716;
  color: lightgray;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CompanyName {
  color: #c06205;
  text-decoration: none;
  font-size: 5vh;
  padding: 10px 0 0 10px;
}

.Navigation {
  padding: 0 70px 0 0;
}

.SectionLink {
  text-decoration: none;
  color: lightgray;
  font-size: 2.5vh;
}

@media only screen and (max-width: 1000px) {
  .Navigation {
    padding: 0 15px 0 0;
  }
  .SectionLink {
    font-size: 4vh;
  }
  .CompanyName {
    font-size: 6vh;
  }
}
