.Footer {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  background-color: #181716;
  color: white;
  padding: 0 0 4vh 0;
}

.socialIcon {
  max-width: 50px;
  padding: 0 5px;
}

.FooterText {
  text-align: center;
}

.ContactText {
  padding: 0 0 1vh 0;
}

.DisclaimersLink {
  text-decoration: none;
  color: lightgray;
}
