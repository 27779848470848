.Box {
  width: 33%;
}

.Card {
  height: 100%;
}

@media only screen and (max-width: 1000px) {
  .Box {
    width: 100%;
  }
}
