.App-header {
  background-color: #181716;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0 10px;
}

.Button1 {
  border: none;
  background-color: #c06205;
  color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 3px;
  font-size: 2vh;
  text-decoration: none;
}

.Section1 {
  display: flex;
  min-height: 100vh;
}

.Section1Header {
  padding: 50px;
}

.Boxes {
  padding: 0 0 6vh 0;
  display: flex;
  flex-wrap: wrap;
}

.Section1HalfImage {
  width: 40vw;
  height: auto;
}

.HalfText {
  width: 50%;
  padding: 5%;
}

.Section2 {
  background-color: #181716;
  height: 100vh;
  display: flex;
}

.Section2HalfText {
  color: white;
  width: 50%;
  padding: 5%;
}

.Section2Header {
  color: white;
  padding: 50px;
  font-size: 4vh;
}

.Section2Text {
  padding: 0 0 100px 0;
  font-size: 3vh;
}

.Section2HalfImage {
  padding: 0 10% 0 0;
}

.Section3 {
  display: flex;
  height: 100vh;
}

.Section3HalfImage {
  max-width: 40%;
  height: auto;
}

.Section3Header {
  font-size: 4vh;
}

.Section3Text {
  text-align: left;
  font-size: 3vh;
}

.Footer {
  background-color: #181716;
}

#section-2-button {
  margin: 0 0 30px 0;
}

@media only screen and (max-width: 1000px) {
  .Button1 {
    border: none;
    background-color: #c06205;
    color: white;
    padding: 20px;
    border-radius: 3px;
    font-size: 4vh;
    text-decoration: none;
  }

  .Section1 {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin-bottom: 20px;
  }

  .Section1Header {
    padding: 50px;
  }

  .Boxes {
    padding: 0 0 2vh 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
    margin: 0 0 20px 0;
  }

  .Section1HalfImage {
    width: 100vw;
    height: auto;
  }

  .HalfText {
    width: 90%;
    padding: 5%;
    min-height: 100vh;
  }

  .Section2 {
    background-color: #181716;
    height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .Section2HalfText {
    color: white;
    width: 90%;
    padding: 5%;
    min-height: 100vh;
  }

  .Section2Header {
    color: white;
    padding: 0;
    font-size: 30px;
  }

  .Section2Text {
    padding: 0 0 70px 0;
    font-size: 20px;
  }

  .Section2HalfImage {
    display: none;
  }

  .Section3 {
    display: flex;
    height: auto;
    min-height: 100vh;
    margin-bottom: 20px;
  }
  .Section3HalfImage {
    display: none;
  }

  .Section3Header {
    font-size: 30px;
    padding: 0 0 20px 0;
  }

  .Section3Text {
    text-align: left;
    font-size: 20px;
    padding: 0 0 30px 0;
  }

  .Footer {
    background-color: #181716;
  }
}
